@charset "UTF-8"
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700&display=swap')

$font-stack: 'Ubuntu', sans-serif
$layout-breakpoint: 50rem

$page-background: #202326

$content-background: white
$content-color: #2b2f33
$content-color2: #a53c00
$content-color-lighter: gray

$content-shade1: #fff4f6
$content-shade2: #e6ebf2

$content-link-color: #00B
$content-link-color-lighter: #BBF

$nav-color1: #a53c00
$nav-color2: #f2dccb
$nav-background1: #fff4f6
$nav-background2: rgba(33,35,38,0.95)
$nav-separator-color1: #9aa6b3
$nav-separator-color2: #42464c

$language-selector-color: #40464c
$language-selected-color: #595959

$footer-background: #535960
$footer-color: #c5d4e5

html
  font-size: medium
  font-family: $font-stack
  background: $page-background

body
  padding: 0
  border: 0
  max-width: 65rem
  min-width: 15rem
  margin: 0 auto

#site-header
  background: $content-background
  text-align: center
  position: relative
  top: 0
  .header-logo-container
    position: absolute
    top: 0
    background: rgba(255,255,255,0.8)
    z-index: 2
  .header-logo
    display: block
    margin: 0 auto
    max-width: 36%
  .header-background
    display: block
    position: relative
    top: 0
    width: 100%
    z-index: 1

#site-content-wrapper
  background: $content-background
  position: relative
  &::after
    display: block
    clear: both
    content: ""

#language-selector
  position: absolute
  top: 0
  right: 0
  font-size: 90%
  color: $language-selector-color
  padding: 0.4rem 1rem
  .selected
    color: $language-selected-color
    text-decoration: none


#main-nav-background
  display: none

#main-nav
  width: calc(100% - 2rem)
  padding: 2rem 1rem
  ul
    list-style: none
    margin: 0
    padding: 0
    border: 0
  li
    position: relative
    color: $nav-color1
    transition: color .3s, background .3s
    + li
      border-top: .1rem solid $nav-separator-color1
    &:hover
      background: $nav-background2
      color: $nav-color2
      > ul
        display: block
        opacity: 1 
  a,span
    display: inline-block
    width: calc(100% - 1.2rem)
    text-decoration: none
    color: inherit
    padding: 0.8rem 0.6rem
  ul ul
    display: none
    opacity: 0
    width: calc(100% - 2rem)
    transition: visibility 0s, opacity 0.3s

    background: $nav-background2
    padding: 0.3rem 1rem 0.6rem
    border-top: 0.2rem solid $nav-color1
    li
      color: $nav-color2
      transition: unset
      transition: color 0.3s
      + li 
        border-top: 1px solid $nav-separator-color2
      &:hover
        color: white
        background: initial
    a,span
      width: 100%
      padding: .8rem 0
    .menu-item-has-children
      > a, > span
        width: calc(100% - 3.2rem)
  .menu-item-has-children
    > .main-nav-expander
      display: inline-block
      text-align: center
      font-size: x-small
      width: 2rem
      padding: 0
    > a, > span
      width: calc(100% - 3.2rem)
  #show-menu-button
    + ul
      display: none
    &:hover+ul,+ul:hover
      display: block

@media screen and (min-width: $layout-breakpoint)
  #main-nav
    float: left
    width: 15rem - 2rem
    height: 100%
    a,span
      padding: 0.4rem 0.6rem
    ul ul
      display: block
      position: absolute
      top: -0.2rem
      left: 100%
      visibility: hidden
      width: 0
      height: 0
      a,span
        padding: 0.4rem 0
    li:hover > ul
      visibility: visible
      width: 15rem - 2rem
      height: auto
    #show-menu-button
      display: none
      + ul
        display: block
  #main-nav-background
    display: block
    position: absolute
    top: 0
    left: 0
    width: 14.5rem
    height: 100%
    background: $content-shade1




  

#site-content
  overflow: auto
  color: $content-color
  padding: 2rem 2rem 3rem 0.5rem
  min-width: 15rem - 2.5rem
  hr
    clear: both
    border: none
    overflow: visible
    border-top: medium double $content-color
    margin-top: 4rem
    width: 75%
    color: inherit
    text-align: center
    max-height: 1rem
    &::after
      content: '\1D13D'
      display: inline-block
      position: relative
      top: -1em
      font-size: 1.5em
      background: $content-background
      padding: 0 0.25em
  ol,ul
    padding-left: 1.5rem
  li
    margin-bottom: 0.5rem
  .programme-list
    list-style: none
    > li
      text-indent: -1.5rem
      > *
        text-indent: 0

  .avoidwrap
    display: inline-block

  h1
    font-size: 1.8em
    margin-bottom: .8em
    margin-top: 1em
  h2
    font-size: 1.6em
    margin-bottom: .7em
    margin-top: .9em
  h3
    font-size: 1.3em
    margin-bottom: .4em
    margin-top: .6em
  & :first-child
    margin-top: 0
  p, dt, dd
    line-height: 150%
    /* hyphens: auto */

  blockquote
    background: $content-shade1
    border-left: 0.1em solid $content-color2
    padding: .001em 1em
    margin: .2em

  cite
    font-family: serif
    font-style: italic

  table
    margin: 1em 0
    tr:nth-child(2n+1)
      background: $content-shade1
    tr:nth-child(2n)
      background: $content-shade2
  .dashed-borders
    border-style: dashed
  td,th
    padding: .3em

  dt
    font-weight: bold
  dd
    margin-left: 1em
    margin-bottom: .2em

  .text-center
    text-align: center
  .text-right
    text-align: right
  .text-bordered
    border: .1rem solid black
    border-radius: .5rem
    padding: .5rem
  .text-gray
    color: $content-color-lighter
  .text-spaced
    letter-spacing: .2rem
  .text-small-caps
    font-variant: small-caps

  .img-center, .img-float-left, .img-float-right
    display: block
    margin: .375em auto 1.25em

  @media screen and (min-width: $layout-breakpoint)
    .img-float-left
      float: left
      margin: 0.375em 1.25em 1.25em 0
    .img-float-right
      float: right
      margin: 0.375em 0em 1.25em 1.25em

  img
    max-width: 100%
    width: auto
    height: auto
    margin: .375em 0 1.75em

  a
    text-decoration: none
    border-bottom: 1px solid $content-link-color-lighter
    color: $content-link-color
    transition: border-bottom .3s
    &:hover
      border-bottom: 1px solid $content-link-color

  .cards
    display: flex
    flex-flow: row wrap
    justify-content: space-around

  .vorstand-gallery
    > div
      text-align: center
      width: 13rem
      background: $content-background
      box-shadow: 0 0 .2rem gray
      margin: .5rem
      padding: .5rem
    img
      display: block
      margin: .5rem auto
      width: 14rem

  .gallery
    display: flex
    flex-flow: row wrap
    justify-content: space-around
    align-items: stretch
    a
      display: block
      border: 0
      background-position: center
      background-size: cover
      height: 200px
      margin: .25rem
      box-shadow: 0 0 0 0 black
      transition: unset
      transition: box-shadow 0.2s
      &:hover
        box-shadow: 0 0 .5rem .1rem black

#site-footer
  background: $footer-background
  padding: 2rem
  border-radius: 0 0 0.7rem 0.7rem
  text-align: center
  color: $footer-color
  font-size: small
  min-height: 3rem
  a
    color: inherit
    text-decoration: underline
  .logo-list
    display: flex
    flex-flow: row wrap
    justify-content: center
    img
      display: block
      height: 6rem

